var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "resource-tag-list" }, [
    _c(
      "div",
      { staticClass: "tag-list-container" },
      _vm._l(_vm.tags, function(tag, idx) {
        return _c("div", { key: idx, staticClass: "resource-tag" }, [
          _vm._v(" " + _vm._s(tag) + " ")
        ])
      }),
      0
    ),
    _vm.activity.length > 0
      ? _c(
          "div",
          { staticClass: "activity-tag" },
          [_c("ActivityTag", { attrs: { activity: _vm.activity, big: "" } })],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }