

























import { ActivitySignal } from '@c/models/Resource'
import { bezierCurve } from '@c/util'
import Vue from 'vue'
import ActivityGrid from './ActivityGrid.vue'

export default Vue.extend({
  name: 'ActivityTag',

  components: {
    ActivityGrid
  },

  props: {
    activity: {
      type: Array as () => ActivitySignal[],
      required: true
    }
  },

  data() {
    return {
      hovered: false
    }
  },

  computed: {
    recentActivity() {
      const activityBuckets = Array.from({ length: 12 }, () => 0)
      const now = new Date()
      this.activity.forEach((a: any) => {
        const date = new Date(a.date)
        const monthDiff =
          now.getMonth() -
          date.getMonth() +
          12 * (now.getFullYear() - date.getFullYear())
        if (monthDiff < 12) activityBuckets[monthDiff] += 1
      })
      return activityBuckets.reverse()
    },

    polyLinePoints() {
      const WIDTH = 500
      const HEIGHT = 100
      let activity = this.recentActivity

      const delta = Math.floor(WIDTH / activity.length)

      // apply bezier curve
      activity = bezierCurve(activity.map((y, i) => ({ x: i, y })))

      // Normalize for visualisation
      const maxActivity = activity.reduce((a, b) => Math.max(a, b.y), 0)
      const recent =
        maxActivity === 0
          ? activity
          : activity.map((a) => ({ x: a.x, y: a.y / maxActivity }))

      return recent
        .map((t) => `${delta * t.x},${HEIGHT - t.y * HEIGHT}`)
        .join(' ')
    },

    activityColor() {
      const totalActivity = this.recentActivity.reduce((a, b) => a + b, 0)
      return totalActivity > 10
        ? '#1EAE70'
        : totalActivity > 5
        ? '#FD8A02'
        : '#8F9399'
    }
  }
})
