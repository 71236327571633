var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "slideselect" }, [
    _c("div", { staticClass: "slideselect-header" }, [
      _vm._v(
        " " +
          _vm._s(_vm.selected.length) +
          " slide" +
          _vm._s(_vm.selected.length === 1 ? "" : "s") +
          " selected "
      )
    ]),
    _vm.subresourcesLoading
      ? _c(
          "div",
          { staticClass: "slideselect-list-loading" },
          [_c("b-loading", { attrs: { active: "", "is-full-page": false } })],
          1
        )
      : _c(
          "div",
          { staticClass: "slideselect-list" },
          _vm._l(_vm.filteredSubresources, function(subresource) {
            return _c(
              "div",
              {
                key: subresource.uuid,
                staticClass: "slideselect-list-item",
                class: { selected: _vm.selected.includes(subresource.uuid) },
                on: {
                  click: function($event) {
                    return _vm.handleSelect(subresource)
                  }
                }
              },
              [
                _c("img", {
                  staticClass: "slideselect-list-item-img",
                  attrs: {
                    src: subresource.preview,
                    alt: "",
                    draggable: "false"
                  },
                  on: {
                    error: function($event) {
                      return _vm.imagesErrored.push(subresource.uuid)
                    }
                  }
                }),
                _c(
                  "div",
                  { staticClass: "slideselect-list-item-btns" },
                  [
                    _c("QualityLabels", {
                      attrs: {
                        "active-label": subresource.label,
                        "source-id": subresource.uuid,
                        "small-labels": false,
                        "show-hover-info": false,
                        "show-edit-labels": false,
                        "bordered-icons": true
                      }
                    })
                  ],
                  1
                )
              ]
            )
          }),
          0
        ),
    _c(
      "div",
      { staticClass: "slideselect-btns" },
      [
        _c("Button", {
          attrs: { text: "Cancel", type: "white" },
          on: {
            click: function($event) {
              return _vm.$emit("close")
            }
          }
        }),
        _c("Button", {
          attrs: { text: "Collect", loading: _vm.subresourcesLoading },
          on: { click: _vm.handleCollect }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }