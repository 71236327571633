












import Vue from 'vue'
import ActivityTag from './activity/ActivityTag.vue'

export default Vue.extend({
  name: 'ResourceTagList',
  components: {
    ActivityTag
  },
  props: {
    tags: {
      type: Array as () => string[],
      default: () => []
    },
    activity: {
      type: Array,
      default: () => []
    }
  }
})
