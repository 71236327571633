<template>
  <div v-on-clickaway="closeMenu" class="collect">
    <div class="collect-trigger-wrapper" @click="toggleMenu">
      <slot name="trigger">
        <b-tooltip
          class="collect-trigger"
          label="Collect slides"
          position="is-top"
          type="is-dark"
        >
          <Button icon="plus-medium" :type="buttonType" :size="size" />
        </b-tooltip>
      </slot>
    </div>
    <transition name="fade">
      <div
        v-show="menuOpen"
        class="collect-menu-wrapper"
        :class="{ alignleft: alignLeft }"
      >
        <div class="collect-menu">
          <div class="collect-menu-item" @click="openSlideSelectionModal()">
            <p class="collect-menu-item-info">
              <img
                src="@c/assets/icons/slides.svg"
                alt=""
                class="collect-menu-item-icon"
              />
              <span class="collect-menu-item-text"
                >Select slides to collect</span
              >
            </p>
          </div>
          <div class="collect-menu-item" @click="collectAllSlides()">
            <p class="collect-menu-item-info">
              <img
                src="@c/assets/icons/curate.svg"
                alt=""
                class="collect-menu-item-icon"
              />
              <span class="collect-menu-item-text"
                >Collect entire presentation</span
              >
            </p>
          </div>
        </div>
      </div>
    </transition>
    <b-modal v-model="showSubresourceSelectModal">
      <SubresourceSelectModal
        :resource-id="resourceId"
        :resource-trace-id="resourceTraceId"
        @close="showSubresourceSelectModal = false"
        @collect="handleCollectSelection"
      />
    </b-modal>
  </div>
</template>

<script>
import Button from '@c/library/Button.vue'
import { mixin as clickaway } from 'vue-clickaway'
import { mapActions } from 'vuex'
import SubresourceSelectModal from '../SubresourceSelectModal.vue'

export default {
  name: 'ResourceCollectMenu',
  components: { Button, SubresourceSelectModal },
  mixins: [clickaway],
  props: {
    resourceId: {
      type: String,
      required: true
    },
    resourceTraceId: {
      type: String,
      required: true
    },
    emitCollect: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: 's'
    },
    buttonType: {
      type: String,
      default: 'white'
    }
  },
  data: () => ({
    menuOpen: false,
    showSubresourceSelectModal: false,
    alignLeft: true
  }),
  methods: {
    ...mapActions(['getSubResourcePage', 'setSlideCollectorOpen']),
    async getAllSlides() {
      const page = await this.getSubResourcePage({
        workspace_id: this.$route.params.workspace_id,
        resource_id: this.resourceId,
        params: {
          page_size: 999,
          ...(this.$route.query.act_as
            ? { act_as: this.$route.query.act_as }
            : {})
        }
      })
      return page
    },
    ...mapActions(['addSlide']),
    toggleMenu(e) {
      this.alignLeft = e.clientX < window.innerWidth / 2
      this.menuOpen = !this.menuOpen
    },
    closeMenu() {
      this.menuOpen = false
    },
    async collectAllSlides() {
      this.closeMenu()
      const slides = await this.getAllSlides()
      this.handleCollectSelection(
        slides.map((s) => ({
          ...s,
          resource_trace_id: this.resourceTraceId
        }))
      )
    },
    openSlideSelectionModal() {
      this.closeMenu()
      this.showSubresourceSelectModal = true
    },
    handleCollectSelection(selection) {
      this.showSubresourceSelectModal = false
      if (
        selection.some((s) => ['warning', 'negative'].includes(s?.label?.type))
      ) {
        const label = selection.find((s) =>
          ['warning', 'negative'].includes(s?.label?.type)
        )?.label
        this.$buefy.toast.open({
          message: `You have collected ${
            selection.lenght === 1 ? 'a slide' : 'slides'
          } with a ${label.type} label.`,
          type: 'is-danger',
          position: 'is-top',
          duration: 5000
        })
      }
      if (this.emitCollect) {
        this.$emit('collect', selection)
        return
      } else {
        selection.forEach(this.addSlide)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.collect {
  position: relative;

  &-menu {
    background: white;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    min-width: 20rem;
    padding: 0.5rem 0;

    &-wrapper {
      position: absolute;
      top: 100%;
      z-index: 99;
      padding-top: 0.5rem;

      &.alignleft {
        left: 0;
      }

      &:not(.alignleft) {
        right: 0;
      }
    }

    &-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 1rem;
      padding: 0.5rem 1.5rem;
      cursor: pointer;

      &:hover {
        background: rgba(#000, 6%);
      }

      &.disabled {
        pointer-events: none;
        opacity: 0.7;
      }

      &-info {
        display: flex;
        align-items: center;
        gap: 0.5rem;
      }

      &-icon {
        height: 1.25rem;
      }

      &-text {
      }

      &-count {
        justify-self: flex-end;
        padding: 0.1rem 0.5rem;
        color: $primary;
        font-weight: 700;
        background: #ebf1ff;
        border-radius: 999rem;
      }
    }

    &-divider {
      background: rgba(#000, 8%);
      height: 1px;
      width: 100%;
      margin: 0.5rem 0;
    }
  }
}

::v-deep .modal-content {
  max-width: 80vw !important;
}
</style>
