var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "activity",
      on: {
        mouseenter: function($event) {
          _vm.hovered = true
        },
        mouseleave: function($event) {
          _vm.hovered = false
        }
      }
    },
    [
      _c(
        "svg",
        { staticClass: "activity-svg", attrs: { viewBox: "0 0 500 100" } },
        [
          _c("polyline", {
            staticClass: "activity-svg-line",
            attrs: { points: _vm.polyLinePoints, stroke: _vm.activityColor }
          })
        ]
      ),
      _vm.hovered
        ? _c("div", { staticClass: "activity-grid-wrapper" }, [
            _c(
              "div",
              { staticClass: "activity-grid" },
              [
                _c("p", { staticClass: "activity-grid-label" }, [
                  _vm._v("Activity in the last year")
                ]),
                _c("ActivityGrid", {
                  attrs: { activity: _vm.activity, "month-labels": "" }
                }),
                _c("p", { staticClass: "activity-grid-label-sub" }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.activity.length) +
                      " change" +
                      _vm._s(_vm.activity.length === 1 ? "" : "s") +
                      " "
                  )
                ])
              ],
              1
            )
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }