var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "on-clickaway",
          rawName: "v-on-clickaway",
          value: _vm.closeMenu,
          expression: "closeMenu"
        }
      ],
      staticClass: "collect"
    },
    [
      _c(
        "div",
        {
          staticClass: "collect-trigger-wrapper",
          on: { click: _vm.toggleMenu }
        },
        [
          _vm._t("trigger", function() {
            return [
              _c(
                "b-tooltip",
                {
                  staticClass: "collect-trigger",
                  attrs: {
                    label: "Collect slides",
                    position: "is-top",
                    type: "is-dark"
                  }
                },
                [
                  _c("Button", {
                    attrs: {
                      icon: "plus-medium",
                      type: _vm.buttonType,
                      size: _vm.size
                    }
                  })
                ],
                1
              )
            ]
          })
        ],
        2
      ),
      _c("transition", { attrs: { name: "fade" } }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.menuOpen,
                expression: "menuOpen"
              }
            ],
            staticClass: "collect-menu-wrapper",
            class: { alignleft: _vm.alignLeft }
          },
          [
            _c("div", { staticClass: "collect-menu" }, [
              _c(
                "div",
                {
                  staticClass: "collect-menu-item",
                  on: {
                    click: function($event) {
                      return _vm.openSlideSelectionModal()
                    }
                  }
                },
                [
                  _c("p", { staticClass: "collect-menu-item-info" }, [
                    _c("img", {
                      staticClass: "collect-menu-item-icon",
                      attrs: {
                        src: require("@c/assets/icons/slides.svg"),
                        alt: ""
                      }
                    }),
                    _c("span", { staticClass: "collect-menu-item-text" }, [
                      _vm._v("Select slides to collect")
                    ])
                  ])
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "collect-menu-item",
                  on: {
                    click: function($event) {
                      return _vm.collectAllSlides()
                    }
                  }
                },
                [
                  _c("p", { staticClass: "collect-menu-item-info" }, [
                    _c("img", {
                      staticClass: "collect-menu-item-icon",
                      attrs: {
                        src: require("@c/assets/icons/curate.svg"),
                        alt: ""
                      }
                    }),
                    _c("span", { staticClass: "collect-menu-item-text" }, [
                      _vm._v("Collect entire presentation")
                    ])
                  ])
                ]
              )
            ])
          ]
        )
      ]),
      _c(
        "b-modal",
        {
          model: {
            value: _vm.showSubresourceSelectModal,
            callback: function($$v) {
              _vm.showSubresourceSelectModal = $$v
            },
            expression: "showSubresourceSelectModal"
          }
        },
        [
          _c("SubresourceSelectModal", {
            attrs: {
              "resource-id": _vm.resourceId,
              "resource-trace-id": _vm.resourceTraceId
            },
            on: {
              close: function($event) {
                _vm.showSubresourceSelectModal = false
              },
              collect: _vm.handleCollectSelection
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }