<template>
  <div class="slideselect">
    <div class="slideselect-header">
      {{ selected.length }} slide{{ selected.length === 1 ? '' : 's' }} selected
    </div>
    <div v-if="subresourcesLoading" class="slideselect-list-loading">
      <b-loading active :is-full-page="false" />
    </div>
    <div v-else class="slideselect-list">
      <div
        v-for="subresource in filteredSubresources"
        :key="subresource.uuid"
        class="slideselect-list-item"
        :class="{ selected: selected.includes(subresource.uuid) }"
        @click="handleSelect(subresource)"
      >
        <img
          :src="subresource.preview"
          alt=""
          class="slideselect-list-item-img"
          draggable="false"
          @error="imagesErrored.push(subresource.uuid)"
        />
        <div class="slideselect-list-item-btns">
          <QualityLabels
            :active-label="subresource.label"
            :source-id="subresource.uuid"
            :small-labels="false"
            :show-hover-info="false"
            :show-edit-labels="false"
            :bordered-icons="true"
          />
        </div>
      </div>
    </div>
    <div class="slideselect-btns">
      <Button text="Cancel" type="white" @click="$emit('close')" />
      <Button
        text="Collect"
        :loading="subresourcesLoading"
        @click="handleCollect"
      />
    </div>
  </div>
</template>

<script>
import Button from '@c/library/Button.vue'
import QualityLabels from './subcomponents/QualityLabels.vue'
import { mapActions } from 'vuex'

export default {
  name: 'SubresourceSelectModal',
  components: { Button, QualityLabels },
  props: {
    resourceId: {
      type: String,
      required: true
    },
    resourceTraceId: {
      type: String,
      required: true
    }
  },
  data: () => ({
    subresources: [],
    subresourcesLoading: false,
    selected: [],
    imagesErrored: []
  }),
  computed: {
    filteredSubresources() {
      return this.subresources.filter(s => !this.imagesErrored.includes(s.uuid))
    }
  },
  created() {
    this.loadSubresources()
  },
  methods: {
    ...mapActions(['getSubResourcePage']),
    async loadSubresources() {
      try {
        this.subresourcesLoading = true
        this.subresources = await this.getSubResourcePage({
          workspace_id: this.$route.params.workspace_id,
          resource_id: this.resourceId,
          params: {
            page_size: 999,
            ...(this.$route.query.act_as
              ? { act_as: this.$route.query.act_as }
              : {})
          }
        })
        this.subresourcesLoading = false
      } catch (e) {
        this.$console.debug('Error getting subresource page', e)
        this.$toast.error(e, 'getting slides')
        this.subresourcesLoading = false
      }
    },
    async handleSelect(subresource) {
      if (this.selected.includes(subresource.uuid)) {
        this.selected = this.selected.filter(s => s !== subresource.uuid)
      } else {
        this.selected = [...this.selected, subresource.uuid]
      }
    },
    handleCollect() {
      this.$emit(
        'collect',
        this.subresources
          .filter(p => this.selected.includes(p.uuid))
          .map(p => ({
            ...p,
            resource_trace_id: this.resourceTraceId
          }))
      )
    }
  }
}
</script>

<style scoped lang="scss">
.slideselect {
  width: 100%;
  max-height: 80vh;
  display: flex;
  flex-flow: column nowrap;
  gap: 1rem;
  padding: 2rem 1rem 2rem 2rem;
  border-radius: 8px;
  background: white;
  isolation: isolate;

  &-header {
    font-weight: 600;
  }

  &-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(25rem, 1fr));
    padding-right: 1rem;
    gap: 0.5rem;
    overflow-y: auto;
    overflow-x: hidden;

    &-loading {
      position: relative;
      min-height: 10rem;
    }

    &-item {
      width: 100%;
      border-radius: 8px;
      cursor: pointer;
      transition: all 0.2s ease-in-out;
      padding: 0.25rem;
      border: 2px solid transparent;
      position: relative;

      &:hover {
        box-shadow: 0 2px 4px rgba(#000, 8%);

        & > .slideselect-list-item-btns {
          opacity: 1;
          pointer-events: all;
        }
      }

      &.selected {
        border: 2px solid $primary;
      }

      &-img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 8px;
        border: 1px solid rgba(#000, 8%);
      }

      &-btns {
        position: absolute;
        top: 1rem;
        right: 0.5rem;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        gap: 0.5rem;
        opacity: 0;
        pointer-events: none;
        transition: opacity 0.2s ease;
      }
    }
  }
  &-btns {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    padding-top: 1rem;
  }
}
</style>
